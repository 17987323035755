@import url('https://fonts.googleapis.com/css2?family=Londrina+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bungee+Outline&family=Orbitron:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

:root {
  /* --font-base: 'Cormorant Upright', serif; */
  --font-base: 'Orbitron', sans-serif;
  --font-alt: 'Orbitron', sans-serif;

  --color-golden: #DCCA87;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}